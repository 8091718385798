<template>
  <v-tooltip
    nudge-top="10px"
    open-delay="400"
    open-on-click
    :open-on-hover="false"
    bottom
    :disabled="disabledTooltip">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on"
        @click="handleClick()"
        :color="disabled ? 'grey' : color"
        :style="disabled ? 'cursor: not-allowed' : ''"
        :class="(disabled && icon ? 'grey lighten-2' : '') + (buttonClass || '')"
        :icon="isIcon"
        :small="small"
        :outlined="outlined"
        :fab="fab"
        :dark="dark"
        :loading="loading"
      >
        <span v-if="!icon">{{ text }}</span>
        <v-icon v-else :size="iconSize" :dark="dark">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <div>{{ tooltipText }}</div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    disabledTooltip: Boolean,
    text: String,
    tooltipText: String,
    buttonClass: String,
    icon: String,
    fab: Boolean,
    color: String,
    iconSize: Number,
    small: Boolean,
    dark: Boolean,
    isIcon: Boolean,
    outlined: Boolean,
    loading: Boolean
  },
  methods: {
    handleClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>
